<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
      ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="mx-auto font-18">Status Penukaran Poin</h1>
      <a data-menu="menu-main" class="page-title-icon rounded-s ms-3"></a>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->

    <!-- Experience List-->
    <div class="page-content mt-4">
      <!-- Search Skill -->
      <div class="" data-card-height="250">
        <div class="px-5 mb-2">
          <div
            class="card"
            v-for="redeem in redeemData"
            :key="redeem.id"
          >
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="fw-bold fs-6">#{{ redeem.id }}</div>
                <div>{{ getDate(redeem.created_at) }}</div>
                <div
                  v-bind:class="[
                    redeem.status.toLowerCase() === 'berhasil' ? 'fw-bold text-success' : '',
                    redeem.status.toLowerCase() === 'proses' ? 'fw-bold text-primary' : '',
                    redeem.status.toLowerCase() === 'ditolak' ? 'fw-bold text-danger' : '',
                  ]"
                >{{ redeem.status.toUpperCase() }}</div>
              </div>
              <div class="row mb-0">
                <div class="col-12 fs-5 fw-bold mt-3 text-center">
                  {{ redeem.MasterItemRedeem?.name_item }}
                </div>
                <div class="col-12 fs-6 text-center">
                  Terpakai {{ new Intl.NumberFormat(['ban', 'id']).format(redeem.MasterItemRedeem?.nominal ?? 0) }} poin
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="userList.length" class="card card-style">
          <div class="content mb-2">
            <h4>History Point</h4>
            <p></p>
            <div class="list-group list-custom-small">
              <a v-for="user in userList" :key="user" href="#">
                <span>{{ user.name }}</span>
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- End Search Skill -->
      <!-- Skill List -->
      <div v-if="redeemData.length === 0" class="mb-5">
        <EmptyState
          :message1="'Belum ada riwayat poin,'"
          :message2="'undang sekarang !'"
          :cardCenter="false"
        />
      </div>
      <!-- Skill List -->
    </div>
    <!-- End Experience List-->

    <!-- Main Menu-->
    <div
      id="menu-main"
      class="menu menu-box-left rounded-0"
      data-menu-width="280"
      data-menu-active="nav-welcome"
    >
      <MenuMain @close="menuClose" />
    </div>

    <!-- Share Menu-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="370"
    ></div>

    <!-- Colors Menu-->
    <div
      id="menu-colors"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-colors.html"
      data-menu-height="480"
    >
      <MenuColors @close="menuClose" />
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import { Toast } from "bootstrap";
import useClipboard from "vue-clipboard3";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmptyState from "@/components/EmptyState";

import MenuMain from "@/components/menu/MenuMain";
import MenuColors from "@/components/menu/MenuColors";
export default {
  name: "PoinSaya",
  components: {
    Header,
    Footer,
    MenuMain,
    MenuColors,
    EmptyState,
  },
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      redeemData: [],
      userList: [],
      isLoading: true,
      inviteLink: "",
      errors: [],
      options: [],
      nextPage: null,
      page: 1,
      limit: 10,
    };
  },
  watch: {
    skill() {
      this.addSkill();
    },
  },
  mounted() {
    init_template();
    this.isLoading = false;
    this.getInviteLink();
    this.getRedeemStatus();
    this.getMoreRedeemStatus();
  },
  methods: {
    menuOpen,
    menuClose,
    async copyLink() {
      const { toClipboard } = useClipboard();

      try {
        await toClipboard(this.inviteLink);
        var notificationToast = document.getElementById("toast-2");
        notificationToast = new Toast(notificationToast);
        notificationToast.show();
      } catch (e) {
        console.error(e);
      }
    },
    getDate(date) {
      const monthName = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mei',
        'Jun',
        'Jul',
        'Ags',
        'Sep',
        'Okt',
        'Nov',
        'Des',
      ];
      if (date !== null) {
        let dateTime = new Date(date);
        return dateTime.getDate() + " " + monthName[dateTime.getMonth()] + " " + dateTime.getFullYear() + " " + dateTime.getHours() + ":" + dateTime.getMinutes()
      } else {
        return ""
      }
    },
    getMoreRedeemStatus() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight - 2;
        if (bottomOfWindow && this.page) {
          console.log("get more redeem status");
          this.getRedeemStatus();
        }
      };
    },
    getRedeemStatus() {
      axios
        .get("/gamification/status-redeem",{
          params: {
            page: this.page,
            limit: this.limit,
          },
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          this.redeemData = [
            ...this.redeemData,
            ...data.data.data,
          ];
          this.page = data.data.next_page
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
    getInviteLink() {
      axios
        .get("/api/v1/auth/invite-code")
        .then((response) => {
          this.inviteLink =
            axios.defaults.baseURL +
            "/sign-up/confirm/?invite_code=" +
            response.data.invite_code;
          // console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>

<style>
.search-box .fa-copy {
  position: absolute;
  left: 0px;
  line-height: 52px;
  padding: 0px 15px 0px 30px;
}
</style>

